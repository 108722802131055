import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Cityportal({ data }) {
  let i = 0
  return [
    // <section key={i++}>
    //   <div className='bgBlock bgImage Cityportal' style={{backgroundImage: `url(${data.images.bgTop})`}}>
    //     <div className="container">
    //       <div className='row'>
    //         <div className='col-md-10' style={{height: '100%'}}>
    //           <div className='titleBlock'>
    //             <h1>{data.title}</h1>
    //             <span id='cityportal-subheader'>{data.subtitle}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    <OverviewComponent
      {...data}
      classComponent="noMarginTop white-bg Cityportal"
      key={i++}
    />,

    <section className="white-bg" key={i++}>
      <div className="image fixed">
        <img src={data.images.section3} alt="City Portal application" />
      </div>
    </section>,

    <section className="white-bg solution" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="noMarginTop">{data.solution.title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
          <div className="col-md-5" style={{ textAlign: "center" }}>
            <img
              src={data.images.picture}
              alt="City Portal application"
              className="Cityportal floatImage"
            />
          </div>
        </div>
      </div>
    </section>,

    <section className="white-bg" key={i++}>
      <div className="image fixed">
        <img src={data.images.section5} alt="City Portal application" />
      </div>
    </section>,

    <section className="white-bg solution last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="Cityportal">{data.delivered.title}</h2>
            <ul>
              {data.delivered.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>,
  ]
}
