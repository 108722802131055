import React from "react"

import "./CityPortalHeader.scss"

export default function CityPortalHeader({ data }) {
  const { images, title, subtitle } = data
  return (
    <section
      className="cityportal-header"
      style={{ backgroundImage: `url(${images.bgTop})` }}
    >
      <div className="cityportal-header-wrapper">
        <div className="cityportal-header-text">
          <h1 className="cityportal-header-title">{title}</h1>
          <p className="cityportal-header-subtitle">{subtitle}</p>
        </div>
      </div>
    </section>
  )
}
