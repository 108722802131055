import {
  PictureTown,
  Picture,
  Section3,
  Section5,
} from "../../images/SingleCase/CityPortal"

const dataForCityportal = {
  type: "project",
  smart: true,
  classComponent: "Cityportal",
  nextLink: "cases/webrtc/",
  prevLink: "cases/service-doc/",
  data: {
    title: "City council portal",
    subtitle:
      "A government project that monitors latest developments in the city and provides access to public services for citizens and tourists. It also allows voting for city initiatives, tracking population representatives and deputies through local addresses, monitoring budget incomes and expenditures as well as checking payment status with utility providers.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Chernomorsk city council is the governmental organization in Odessa region. Founded in 1973 and managing 4 populated areas. Main activities - providing government services to local population as well as maintenance and development of local infrastructure.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "City Council website was created in early 2000s and was used for governmental and social reporting needs. However, very poor UX resulted in very low traffic - both externally and from City Council Employees themselves. The need for the refreshed concept became apparent. As the project was initiated by government body, the resources and options were limited but included: publishing City Council updates within the Regional Council website; reshaping existing website into the Transparent Government portal; creating knowledge sharing system and integrating it with the document workflow system.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "Symfony2: Sonata Project",
            "Twig",
            "Doctrine",
            "Sphinx",
            "NGINX",
            "MySQL 5",
            "jQuery",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "QA Engineers",
              count: 2,
            },
            {
              type: "Designer",
              count: 1,
            },
            {
              type: "Developers",
              count: 5,
            },
            {
              type: "System architect",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 10,
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "Though much different in their scope, budget and value focus, all the options would solve customer pain. OSSystem submitted its proposals for evaluation through the Centralized Transparent Procurement System within January-March 2016.",
        "Considering the overall trend for increased government transparency in Ukraine, OSSystem's proposal was accepted and modified to share not only City Council updates but also financial, legislative, cultural and social initiatives with the citizens and city.",
        "Before jumping into the implementation, we have prototyped the core functionality of the completed service and outlined non-functional requirements for the system. Based on such requirements, we have drafted the initial architecture for Client web-application and picked the technology stack.",
        "As the requirements included high reliability and maintainability, we decided to stick to the stable and widely available PHP for the server-side and JS for client side. We also selected PHP Symfony for its large number of ready-to-use bundles and Sonata Project for building up the Admin interface.",
        "We have started with design prototyping and laying down architecture at the same time as these processes were largely independent from each other. The designer worked in continuous contact with Product Owner to outline User Personas based on the analysis of current and potential visitors of such platform.",
        "Following that, we have identified User Values and sketched User Journeys for each Persona to create individual screen flows for each of them. Based on such flows, we drafted wireframes and final designs that were validated with sample user groups.",
        "Such platform should be integrated with the central government databases to track directives, law initiatives, citizen petitions and polls and should allow population to easily participate in the local governing.",
        "Existing database structure and its integrity were not maintained due to the involvement of several rotating teams and absence of documentation. We had to manually identify common patterns for data structure, sort, filter and extract the most valuable data first, while making sure that nothing goes missing.",
        "The Head of City Council IT Department acted as a Product Owner. How- ever, due to frequent shifting external priorities, she had to continuously reconcile short-term project objectives.",
        " Our goal as a partner was to fulfill short-term goals without compromising project objectives and schedule. It was up to the team to incorporate new requirements into the scope without compromising the deadline."
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Higher accessibility of government services",
        "Increased government local government transparency",
        "UX and UI improvement",
        "Web-site performance increased",
      ],
    },
    images: {
      bgTop: PictureTown,
      section3: Section3,
      picture: Picture,
      section5: Section5,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/city-portal",
}

export default dataForCityportal
